.leaflet-control-zoom {
  border: 2px solid #444 !important;
  border-radius: 4px !important;
  background-color: rgba(0, 0, 0, 0.8) !important;
  box-shadow: 0 1px 2px rgba(255, 255, 255, 0.1) !important;
}

.leaflet-control-zoom a {
  width: 30px !important;
  height: 30px !important;
  line-height: 30px !important;
  font-size: 18px !important;
  color: #fff !important;
  text-align: center !important;
  text-decoration: none !important;
  background-color: #222 !important;
  border-bottom: 1px solid #444 !important;
}

.leaflet-control-zoom a:last-child {
  border-bottom: none !important;
}

.leaflet-control-zoom a:hover {
  background-color: #333 !important;
}

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  font-weight: bold !important;
}

.black-background {
  background-color: black;
}

.leaflet-container {
  /* Transparent */
  background: transparent !important;
  outline: 0;
}

.cloud-overlay {
  opacity: 1;
  background: linear-gradient(45deg, white 25%, transparent 25%, transparent 75%, white 75%, white),
              linear-gradient(45deg, white 25%, transparent 25%, transparent 75%, white 75%, white),
              linear-gradient(to right, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)),
              linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8));
  background-size: 20px 20px, 20px 20px, 100% 100%, 100% 100%;
  background-position: 0 0, 10px 10px, 0 0, 0 0;
  /* blur filter */
  filter: blur(5px);
  /* Pixelate */
}
